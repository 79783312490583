import {useLocation, useNavigate} from 'react-router-dom';
import { IUser } from "../../app/store/users/user.type";
import {isValidUrl, TG, ucFirst, calculateAge, declension} from "../../functions/global";
import styles from "./UserProfile.module.scss"

import React, { useEffect } from "react"
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg'
import { ReactComponent as AboutIcon } from '../../assets/icons/about.svg'
import {Avatar} from "../Avatar";
import {SocialIcon} from "react-social-icons";

export const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Явное приведение состояния к нужному типу
  const user = (location.state as { user: IUser })?.user;

  useEffect(() => {
    window.scrollTo(0, 0)
    TG.BackButton.show()
    const handleBackButtonClick = () => {
      navigate('/')
    }
    if (!user) handleBackButtonClick()
    TG.onEvent('backButtonClicked', handleBackButtonClick);
    return () => {
      TG.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  return (
      <>
        <div className={styles.container}>
          <div className={styles.avatar}>
            <Avatar images={[user.avatarUrl, ...user.images || []]} gallery={true} />
          </div>
          <div className={styles.name}>{user.fullName}</div>

          <div className={styles.info}>
            <div className={styles.wrapper}>
              {user.blogTheme && (
                  <div className={`${styles.item}`}>
                    {ucFirst(user.blogTheme)}
                  </div>
              )}
              {user.followersCount && (
                  <div className={`${styles.item}`}>
                    Подписчики: {user.followersCount}
                  </div>
              )}
              {user.city && (
                  <div className={`${styles.item} ${styles.city}`}>
                    Город: {ucFirst(user.city)}
                  </div>
              )}
              {user.gender && (
                  <div className={`${styles.item}`}>
                    Пол: {user.gender}
                  </div>
              )}
              {user.birthDate && (
                  <div className={`${styles.item}`}>
                    Возраст: {calculateAge(user.birthDate)} {declension(calculateAge(user.birthDate), ['год', 'года', 'лет'])}
                  </div>
              )}

              {user.skills && (
                <div className={`${styles.item}`}>
                  {user.skills?.map((item) =>ucFirst(item))}
                </div>
              )}
              {user.info && (
                <>
                  <div className={styles.title}><AboutIcon /> О пользователе</div>
                  <div className={styles.text}>{user.info}</div>
                </>
              )}
              {user.expertise && (
                  <div className={`${styles.item} ${styles.expertise}`}>
                    {user.expertise?.map((item) =>ucFirst(item))}
                  </div>
              )}
              {user.education && (
                <div className={`${styles.item}`}>
                  {user.education?.map((item) =>ucFirst(item))}
                </div>
              )}
              {user.additionalEducation && (
                <div className={`${styles.item}`}>
                  {user.additionalEducation?.map((item) =>ucFirst(item))}
                </div>
              )}
              {user.workplace && (
                <div className={`${styles.item}`}>
                  {user.workplace?.map((item) =>ucFirst(item))}
                </div>
              )}
              {user.achievements && (
                <div className={`${styles.item}`}>
                  {user.achievements?.map((item) =>ucFirst(item))}
                </div>
              )}
            </div>
            {user.socialLinks && (
                <>
                  <div className={styles.title}><LinkIcon /> Социальные сети</div>
                  <div className={styles.socials}>
                    {user.socialLinks.map((link, index) => (
                      <a key={index} href={isValidUrl(link) ? link : 'http://' + link} target="_blank"
                         rel="noopener noreferrer">
                        <SocialIcon url={isValidUrl(link) ? link : 'http://' + link} />
                      </a>
                    ))}
                  </div>
                </>
            )}
            {user.videoLinks && (
              <>
                <div className={styles.title}><LinkIcon />Ссылка на видео</div>
                <div className={styles.socials}>
                  {user.videoLinks.map((link, index) => (
                    <a key={index} href={isValidUrl(link) ? link : 'http://' + link} target="_blank"
                       rel="noopener noreferrer">
                      <SocialIcon url={isValidUrl(link) ? link : 'http://' + link} />
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
  )
};