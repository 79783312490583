import React, { useEffect, useState } from 'react';
import 'swiper/css';
import styles from './Slider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

interface BannerItem {
  id: number;
  image_url: string;
  content_url?: string;
}

export const Slider: React.FC = () => {
  const [banners, setBanners] = useState<BannerItem[]>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/banners`)
      .then(response => response.json())
      .then(data => setBanners(data))
      .catch(err => console.error('Failed to fetch banners', err));
  }, []);

  return (
    <Swiper
      className={styles.swiper}
      spaceBetween={50}
      slidesPerView={1}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={index} className={styles.slide}>
          {
            banner.content_url?.length ?
            <a href={banner.content_url}>
              <img src={banner.image_url} alt={`slide-${index}`}/>
            </a>
            : <img src={banner.image_url} alt={`slide-${index}`}/>
          }
            </SwiperSlide>
            ))}
        </Swiper>
      );
      };